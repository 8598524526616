<template>
  <div class="content">
    <p class="title">《隐私协议》</p>
    <p>
      面粉坊是一款由鲁山县焕值面粉厂（以下简称“我们”）研发和运营的产品。我们将通过《面粉坊隐私政策》帮助您了解面粉坊会如何收集、使用和存储您的个人信息及您享有何种权利。其中要点如下：
    </p><p>
    i. 我们将说明我们收集的您的个人信息类型及其对应的用途，以便您了解我们针对某一特定功能所收集的具体个人信息的类别、使用理由及收集方式。
    </p><p>
    ii. 当您使用一些功能时，我们会在获得您的同意后，收集您的一些敏感信息，例如您在使用面粉坊时我们会收集您的地理位置信息。除非按照相关法律法规要求必须收集，拒绝提供这些信息仅会使您无法使用相关特定功能，但不影响您正常使用面粉坊的其他功能。
    </p><p>
    iii. 目前，面粉坊不会主动共享或转让您的个人信息至鲁山县焕值面粉厂外的第三方，如存在其他共享或转让您的个人信息或您需要我们将您的个人信息共享或转让至鲁山县焕值面粉厂外的第三方情形时，我们会直接或确认第三方征得您对上述行为的明示同意，此外，我们会对对外提供信息的行为进行风险评估。
    </p><p>
    iv. 目前，面粉坊不会主动从鲁山县焕值面粉厂外的第三方获取您的个人信息。如未来为业务发展需要从其他第三方间接获取您的个人信息，我们会在获取前向您明示您个人信息的来源、类型及使用范围，如面粉坊开展业务需进行的个人信息处理活动超出您原本向第三方提供个人信息时的授权同意范围，我们将在处理您的该等个人信息前，征得您的明示同意；此外，我们也将会严格遵守相关法律法规的规定，并要求第三方保障其提供的信息的合法性。
    </p><p>    
    v. 您可以通过本指引所列途径访问、更正、删除您的个人信息，也可以撤回同意、注销帐号以及设置隐私功能。
    </p><p>
    1.我们收集的信息
    </p><p>
    在您使用面粉坊服务的过程中，面粉坊会按照如下方式收集您在使用服务时主动提供或因为使用服务而产生的信息，用以向您提供服务、优化我们的服务以及保障您的帐号安全：
    </p><p>
    1.1 当您注册使用面粉坊时，我们会收集您的机构名称、手机号码、角色信息。收集这些信息是为了帮助您完成面粉坊帐号的试用，保护您的帐号安全。已签约机构会有额外的合同来保证隐私的保护。手机号码属于敏感信息，收集此类信息是为了满足相关法律、法规关于网络实名制要求。若您不提供这类信息，您可能无法正常使用我们的服务。您还可以根据自身需求选择填写性别、头像、昵称等信息。
    </p><p>
    1.2 当您使用面粉坊服务时，为保障您正常使用我们的服务，维护我们服务的正常运行，改进及优化我们的服务体验以及保障您的帐号安全，我们会收集您的设备型号、操作系统、设备Mac地址、唯一设备标识符、应用ID、登陆IP地址、面粉坊软件版本号、接入网络的方式、类型和状态、网络质量数据、操作日志、服务日志信息（如您在使用过程中出现的服务故障信息）等日志信息，这类信息是为提供服务必须收集的基础信息。
    </p><p>
    另外，根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用您的相关个人信息无需征求您的授权同意：
    </p><p>
    1) 与国家安全、国防安全等国家利益直接相关的；与公共安全、公共卫生、公众知情等重大公共利益直接相关的；
    </p><p>
    2) 与犯罪侦查、起诉、审判和判决执行等直接相关的；
    </p><p>
    3) 出于维护您或其他个人的生命、财产、声誉等重大合法权益但又很难得到本人同意的；
    </p><p>
    4) 所收集的个人信息是您自行向社会公众公开的；
    </p><p>
    5) 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
    </p><p>
    6) 根据您要求签订和履行合同所必需的；
    </p><p>
    7) 用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
    </p><p>
    8) 为开展合法的新闻报道所必需的；
    </p><p>
    9) 出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
    </p><p>
    10) 法律法规规定的其他情形。
    </p><p>
    请您理解，我们向您提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且收集了您的信息，我们会通过页面提示、交互流程、网站公告等方式另行向您说明信息收集的内容、范围和目的，以征得您的同意。
    </p><p>
    请您注意，目前面粉坊不会主动从鲁山县焕值面粉厂外的第三方获取您的个人信息。如未来为业务发展需要从其他第三方间接获取您个人信息，我们会在获取前向您明示您个人信息的来源、类型及使用范围，如面粉坊开展业务需进行的个人信息处理活动超出您原本向第三方提供个人信息时的授权同意范围，我们将在处理您的该等个人信息前，征得您的明示同意；此外，我们也将会严格遵守相关法律法规的规定，并要求第三方保障其提供的信息的合法性。
    </p><p>
    2.信息的存储
    </p><p>
    2.1 信息存储的地点
    </p><p>
    我们会按照法律法规规定，将境内收集的用户个人信息存储于中国境内。
    </p><p>
    目前我们不会跨境传输或存储您的个人信息。将来如需跨境传输或存储的，我们会向您告知信息出境的目的、接收方、安全保证措施和安全风险，并征得您的同意。
    </p><p>
    2.2 信息存储的方式和期限
    </p><p>
    手机号码：若您需要使用面粉坊服务，我们需要一直保存您的手机号码，以保证您正常使用该服务，当您注销面粉坊帐户后，我们将删除相应的信息；
    </p><p>
    身份证：若您需要使用面粉坊服务，在涉及到您的财产利益时，我们需要保存您的身份证信息，以防他人侵犯您的合法权益，当您注销面粉坊帐户后，我们将删除相应的信息；
    </p><p>
    图片或视频：当您使用成长档案、家校等功能发布了图片、视频，我们需要保存您上述信息及相关评论，以保证您正常使用；当您主动删除上述信息后，我们将删除相应的信息。 当我们的产品或服务发生停止运营的情形时，我们将以推送通知、公告等形式通知您，并在合理的期限内删除您的个人信息或进行匿名化处理。
    </p><p>
    3.信息安全
    </p><p>
    3.1 安全保护措施
    </p><p>
    我们努力为用户的信息安全提供保障，以防止信息的泄露、丢失、不当使用、未经授权访问和披露等。我们使用多方位的安全保护措施，以确保用户的个人信息保护处于合理的安全水平，包括技术保护手段、管理制度控制、安全体系保障等诸多方面。此外，我们还通过了国家网络安全等级保护的测评和备案。
    </p><p>
    我们采用业界领先的技术保护措施。我们使用的技术手段包括但不限于防火墙、加密（例如SSL）、去标识化或匿名化处理、访问控制措施等。此外，我们还会不断加强安装在您设备端的软件的安全能力。例如，我们会在您的设备本地完成部分信息加密工作，以巩固安全传输；我们会了解您设备安装的应用信息和运行的进程信息，以预防病毒、木马等恶意程序。
    </p><p>
    我们建立了保障个人信息安全专门的管理制度、流程和组织。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务并进行审计，违反义务的人员会根据规定进行处罚。我们也会审查该管理制度、流程和组织，以防未经授权的人员擅自访问、使用或披露用户的信息。
    </p><p>
    3.2 安全事件处置措施
    </p><p>
    若发生个人信息泄露、损毁、丢失等安全事件，我们会启动应急预案，阻止安全事件扩大。安全事件发生后，我们会及时以推送通知、邮件等形式告知您安全事件的基本情况、我们即将或已经采取的处置措施和补救措施，以及我们对您的应对建议。如果难以实现逐一告知，我们将通过公告等方式发布警示。
    </p><p>
    4.我们如何使用信息
    </p><p>
    我们严格遵守法律法规的规定以及与用户的约定，按照本隐私保护指引所述使用收集的信息，以向您提供更为优质的服务。
    </p><p>
    4.1 信息使用规则
   </p><p> 
    我们会按照如下规则使用收集的信息：
  </p><p>
    1) 我们会根据已收集的信息向您提供各项功能与服务；
  </p><p>
    2） 我们会根据已收集的信息加工后向您展示统计数据情况；
  </p><p>
    3） 为了保证帐号安全，我们会使用您的地理位置信息进行安全检测，以便为您提供更安全的帐号注册服务；
  </p><p>
    4） 我们可能将通过某些功能所收集的信息用于我们的其他服务。例如，我们可能将你在使用我们某一功能或服务时我们收集的信息，在另一功能或服务中用于向你提供特定内容，包括但不限于展示广告、对你阅读过的内容进行信息安全类提示、基于特征标签进行间接人群画像并提供更加精准和个性化的服务和内容等；
  </p><p>
    5） 我们会根据您使用面粉坊的频率、故障信息、性能信息等分析我们产品的运行情况，以确保服务的安全性并优化我们的产品，提高我们的服务质量。我们不会将我们存储在分析软件中的信息与您提供的个人身份信息相结合。
  </p><p>
    4.2 告知变动目的后征得同意的方式
  </p><p>
    我们将会在本隐私保护指引所涵盖的用途内使用收集的信息。如我们使用您的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，我们将在使用您的个人信息前，再次向您告知并征得您的明示同意。
  </p><p>
    5.对外提供
  </p><p>
    目前，我们不会主动共享或转让您的个人信息至鲁山县焕值面粉厂外的第三方，如存在其他共享或转让您的个人信息或您需要我们将您的个人信息共享或转让至鲁山县焕值面粉厂外的第三方情形时，我们会直接或确认第三方征得您对上述行为的明示同意。
  </p><p>
    我们不会对外公开披露其收集的个人信息，如必须公开披露时，我们会向您告知此次公开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的明示同意。
  </p><p>
    随着我们业务的持续发展，我们有可能进行合并、收购、资产转让等交易，我们将告知您相关情形，按照法律法规及不低于本指引所要求的标准继续保护或要求新的控制者继续保护您的个人信息。
  </p><p>
    另外，根据相关法律法规及国家标准，以下情形中，我们可能会共享、转让、公开披露个人信息无需事先征得个人信息主体的授权同意：
  </p><p>
    1） 与国家安全、国防安全直接相关的；
  </p><p>
    2） 与公共安全、公共卫生、重大公共利益直接相关的；
  </p><p>
    3） 与犯罪侦查、起诉、审判和判决执行等直接相关的；
  </p><p>
    4） 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
  </p><p>
    5） 个人信息主体自行向社会公众公开的个人信息；
  </p><p>
    6） 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
  </p><p>
    6.您的权利
  </p><p>
    在您使用面粉坊期间，为了您可以更加便捷地访问、更正、删除您的个人信息，同时保障您撤回对个人信息使用的同意及注销帐号的权利，我们在产品设计中为您提供了相应的操作设置。此外，我们还设置了客服，您的意见将会得到及时的处理。
  </p><p>
    7.变更
  </p><p>
    我们可能会适时对本指引进行修订。当指引的条款发生变更时，我们会在您登录及版本更新时以推送通知、弹窗的形式向您展示变更后的指引。请您注意，只有在您点击弹窗中的同意按钮后，我们才会按照更新后的指引收集、使用、存储您的个人信息。
  </p><p>
    8.未成年人保护
  </p><p>
    我们非常重视对未成年人个人信息的保护。根据相关法律法规的规定，若您是18周岁以下的未成年人，在使用面粉坊服务前，应事先取得您的家长或法定监护人的书面同意。若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过第9节中的联系方式与我们联系。
  </p><p>
    9.与我们联系
  </p><p>
    当您有其他的投诉、建议、未成年人个人信息相关问题时，请通过 895157249@qq.com与我们联系。或寄到如下地址：
  </p><p>
    鲁山县让河乡面粉厂
  </p><p>
    邮编：467331
  </p><p>
    我们将尽快审核所涉问题，并在验证您的用户身份后的十五天内予以回复。
  </p>
  </div>

</template>

<script>
export default {
  name: "privacy"
}
</script>

<style lang="scss">
.content {
  margin: 1.5rem;
}

.content .title{
  text-align: center;
}
.content p{
  text-indent:2em
}
</style>